:root {
  --primary: #34522a;
  --secondary: #3c5919;
  --logoGuide: #53682b;
  --medium: #c1bb00;
  --primaryText: #f7f3bc;
  --bg: #f9f8eb;
  --blue: #dbede6;
  --bubbleGreen: #92a97e;
  --textBlack: rgba(0, 0, 0, 0.87);
}

.MuiTypography-gutterBottom {
  margin-bottom: 16px !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.certifikat {
  max-height: 500px;
}

.container {
  max-width: 1200px;
  margin: auto;
  padding: 0 20px;
}

.dropdown-btn {
  margin-left: auto !important;
}

.Navbar span {
  font-weight: normal !important;
}

.Nav > *:not(:first-child) {
  margin: 0 2px;
}

.extra-margin {
  margin-top: 70px !important;
}

/*


.Nav > a::after {
  content: "";
  display: block;
  width: 0;
  height: 1px;
  border-radius: 1px;
  background: var(--secondary);
  transition: width 0.3s;
}

.Nav > a:hover::after {
  width: 100%;
}

.Nav .active {
  border-bottom: solid 1px var(--secondary);
}
*/

a {
  color: inherit;
  text-decoration: none;
}

.HomeImg {
  width: 100%;
  min-height: 60vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;
}

.HomeImg img {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: 0;

  /* filter: brightness(80%); */
}

.HomeImg .layer {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: 0;
  background-color: #c1bb0033; /* #40533070; */
}

.layer {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: 0;
  background-color: #c1bb0033; /* #40533070; */
}

.HomeImg h3 {
  font-weight: bold;
  color: white;
  z-index: 1;
  width: 60%;
  align-self: center;
  text-align: center;
}

.HomeImg h5 {
  color: var(--bg);
  z-index: 1;
  width: 60%;
  align-self: center;
  text-align: center;
}

.HomeImg button {
  align-self: center;
}

.extraLarge {
  font-size: 2rem !important;
}

.padding {
  padding: 0 20px;
}

footer {
  width: 100%;
  padding-top: 50px;
  margin-top: 70px;
  height: 350px;
  position: relative;
  background: white;
  box-shadow: 0 -50px 50px 0 #c5c2942d;
  z-index: 0;
}

footer > img {
  height: calc(100% + 170px);
  width: 100%;
  left: 0;
  right: 0;
  z-index: -1;
  top: -170px;
  position: absolute;
}

footer .container {
  height: 100%;
}

footer .push-bottom {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  padding-bottom: 10px;
}

footer svg {
  fill: var(--primary) !important;
}

.footer-links-container {
  flex-direction: row;
  display: flex;
  align-items: flex-start;
}

.Navigation {
  min-height: 80vh;
}

.social-media {
  width: 100%;
  display: flex;
}

.social-media > * {
  margin-right: 10px;
}

.footer-links {
  display: flex;
  flex-direction: column;

  justify-content: flex-end;
}

.footer-links > * {
  margin-bottom: 20px;
}

.footer-links h6 {
  color: var(--primary);
  font-weight: 600;
}

.logo {
  cursor: pointer;
}

.flex img {
  width: 90%;
  border-radius: 14px;
}

.flex > * {
  flex: 0 0 50%;
}

.m-auto {
  margin: auto !important;
}

.semi-bold {
  font-weight: 500 !important;
}

.bold {
  font-weight: bold !important;
  color: var(--primary);
}

.steps {
  height: 170px;
  width: 170px;
}

.steps img {
  height: 170px;
  width: 170px;
}

.fade {
  animation: fade 0.3s ease-in;
}

.text--center {
  text-align: center;
}

.pipe {
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.pipe-item {
  position: relative;
  top: -7px;
  height: 70px;
  width: 3px;
  background-color: var(--primary);
}

.dummy {
  position: absolute;
  top: -50px;
  width: 100%;
  height: 50px;
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.stepper {
  border-radius: 14px;
  padding: 10px;
  background-color: white;
}

.step {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.step img {
  margin-top: 10px;
  border-radius: 4px;
}

.text--primary {
  color: var(--primary);
}

.text--secondary {
  color: var(--secondary);
}

.text--primary-text {
  color: var(--primaryText);
}

.gap {
  margin-top: 30px;
}

.faq-btn {
  margin-bottom: 10px !important;
  align-self: center;
}

div.MuiAlert-standardInfo {
  background-color: var(--primary);
  color: white;
}

div.MuiAlert-standardInfo svg {
  color: white;
}

.height-placeholder {
  height: 200px;
}

.slider-container svg {
  margin: 5px 0;
}

.Overgang {
  color: var(--primary);
  background-color: white;
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 10px;
  padding-top: 40px;
  padding-bottom: 40px;
  border-top: solid 2px var(--primary);
  border-bottom: 2px solid var(--primary);
  position: relative;
}

.FaqItem {
  transition: transform 0.2s ease-in !important;
  transform: scale(1);
  cursor: pointer;
}

.FaqItem:hover {
  transform: scale(1.05);
}

.sss {
  background-color: var(--primaryText);
  color: var(--primary);
  padding-top: 50px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.food-circle {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  animation: 0.3s ease-in fade;
}

.big-step > div {
  max-width: 450px !important;
}

.MuiExpansionPanel-root.Mui-disabled {
  background-color: white !important;
}

.MuiExpansionPanelSummary-root.Mui-disabled {
  opacity: 1 !important;
}

div.EatAlert {
  background-color: var(--medium);
  color: white;
}

div.EatAlert svg {
  fill: white;
}

.curved {
  position: relative;
  background: var(--primary);
  padding-top: 50px;
  height: 50vh;
  /* border-bottom-left-radius: 50% 57%; */
  /* border-bottom-right-radius: 50% 57%; */
}

.curved p {
  color: white;
}

.curved h6 {
  color: white;
}

.curved h4 {
  color: white;
}

.squeeze {
  align-items: center;
  max-width: 60%;
  margin: auto !important;
}

.pulse {
  animation: pulse 1s ease-in infinite;
}

.klima-maerke-abs {
  text-align: center;
  position: relative;
}

.wrapperino {
  display: flex;
}

.languages {
  margin-left: auto;
}

.klima-flags img {
  height: 15px;
  cursor: pointer;
}

.klima-flags img:not(:last-child) {
  margin-right: 5px;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

@media (max-width: 600px) {
  .flex img {
    max-width: 100%;
  }
  .footer-links-container {
    flex-direction: column;
  }
  footer > img {
    bottom: 0;
    top: unset;
    height: 200px;
  }
  .footer-links {
    color: var(--primary);
  }
  .certifikat {
    max-height: 300px;
  }
  footer {
    height: 100%;
  }
  .food-circle {
    display: none;
  }
  .big-img {
    width: 100%;
    object-fit: contain;
  }
  .Overgang img {
    display: none;
  }
  .push-bottom > div {
    align-items: flex-end;
  }
  .big-step > div {
    max-width: 300px !important;
  }
  .bub_4 {
    right: 0 !important;
  }
  .klima-maerke-abs img:nth-child(2) {
    left: 0 !important;
  }
  .klima-maerke-abs img:nth-child(3) {
    right: 0 !important;
    left: auto !important;
  }
  .wrapperino {
    flex-direction: column;
  }
  .hide-me {
    display: none;
  }
  html {
    font-size: 13px;
  }
  .container {
    padding: 0 10px;
  }
  div.MuiButtonBase-root.MuiExpansionPanelSummary-root {
    padding: 0;
  }
  .curved {
    border-bottom-left-radius: 50% 25%;
    border-bottom-right-radius: 50% 25%;
    padding-bottom: 150px;
    margin-bottom: 20px;
    height: auto;
  }
  .footer-links {
    margin-right: 0 !important;
    margin-left: 5px;
  }
  .languages {
    margin-right: 50px;
  }
}

/* */
